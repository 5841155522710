<template>
    <div class="c-widget--gallery o-container">
        <div ref="container" class="c-gallery" :class="{ 'c-gallery--in-viewmode': viewMode }" :style="centerGallery">
            <div ref="wrapper" class="c-gallery__wrapper">
                <siema class="c-gallery__slider" :options="options" :current.sync="curSlide" @init="initGallery"
                       ref="gallery">

                    <div role="img" v-for="(img, i) in data.images" class="c-gallery__img"
                         :style="{ backgroundImage: `url(${ img.image.sizes.large.src[0] })` }"
                         :aria-label="img.image.alt"
                         :class="{ 'c-gallery__fig--is-active' : curSlide === i }" :key="i"></div>
                </siema>

                <div ref="dots" class="c-gallery__controls c-gallery__controls--dots">
                    <button v-for="(img, j) in data.images" class="c-gallery__dot c-btn c-btn--gallery-dot"
                            :class="{ 'c-btn--gallery-dot-active' : curSlide === j }" :key="j"
                            @click="$refs.gallery.goTo(j)">
                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="9.407" cy="9.657" r="8.309"/></svg>
                    </button>
                </div>

                <div class="c-gallery__controls c-gallery__controls--arrows">
                    <button class="c-gallery__arrow" @click="$refs.gallery.prev()"><svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 15" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 1l6 6.5L1 14"/></svg></button>
                    <button class="c-gallery__arrow" @click="$refs.gallery.next()"><svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 15" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 1l6 6.5L1 14"/></svg></button>
                </div>
            </div>
            <p ref="caption" class="c-gallery__text" :class="{ 'c-gallery__text--is-active' : curSlide === k }"
               v-for="(img, k) in data.images" :key="k"><span>{{ img.image_caption }}</span></p>
            <button class="c-btn c-btn--toggle-gallery c-txt--upper" @click="viewMode = !viewMode">
                Galerie öffnen
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 13" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.507 1h6.742v6.742M7.992 12H1.25V5.258"/></svg>
            </button>
        </div>
        <div class="c-backdrop">
            <button class="c-btn c-txt--upper c-btn--close" @click="viewMode = !viewMode">
                <span>Galerie schließen</span> &times;
            </button>
        </div>
        <!-- TODO: Close Gallery on ESC and click outside gallery -->
    </div>
</template>

<script>
    export default {
        name: 'PostGallery',
        props: {
            data: Object
        },

        data() {
            return {
                curSlide: 0,
                options: {
                    threshold: 40
                },
                viewMode: false
            }
        },

        watch: {
            'viewMode': function (state) {
                this.viewModeActive(state)
            }
        },

        methods: {
            initGallery() {
                this.$refs.container.append(this.$refs.caption[0])
            },

            viewModeActive(state) {
                document.querySelector('html').style.overflow = state ? 'hidden' : '';
            },
        },

        computed: {
            centerGallery() {
                if (!this.viewMode) {
                    return false
                }

                const windowBox = {width: window.innerWidth, height: window.innerHeight}
                const galleryBox = this.$refs.gallery.$el.getBoundingClientRect();

                const centerY = (windowBox.height / 2) - (galleryBox.height / 2 + galleryBox.y);
                const centerX = (windowBox.width / 2) - (galleryBox.width / 2 + galleryBox.x);

                return {
                    transform: `translate3d(${centerX}px, ${centerY}px, 0) scale(1.1)`,
                }
            }
        },

        beforeDestroy() {
            this.$refs.gallery.destroy()
        }

    }
</script>

<style scoped lang="scss" src="@/styles/components/_gallery.scss"></style>